import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import PrivacyPolicy from '../components/PrivacyPolicy/PrivacyPolicy';
import config from '../../data/SiteConfig';

import Drawer from '../layouts/Drawer/Drawer';
import Navigation from '../components/Navigation/Navigation';
import SiteWrapper from '../layouts/SiteWrapper/SiteWrapper';
import MainHeader from '../layouts/MainHeader/MainHeader';
import MainNav from '../layouts/MainNav/MainNav';
import MainContent from '../layouts/MainContent/MainContent';
import BlogLogo from '../components/BlogLogo/BlogLogo';
import MenuButton from '../components/MenuButton/MenuButton';
import Footer from '../components/Footer/Footer';

const PrivacyPolicyPage = props => {
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleOnClick = React.useCallback(evt => {
    evt.stopPropagation();
    setMenuOpen(!menuOpen);
  }, [menuOpen, setMenuOpen]);

  const handleOnClose = React.useCallback(evt => {
    evt.stopPropagation();
    setMenuOpen(false);
  }, [setMenuOpen]);

  return (
    <Drawer className="page-template" isOpen={menuOpen}>
      <Helmet>
        <title>{`Privacy Policy | ${config.siteTitle}`}</title>
      </Helmet>

      {/* The blog navigation links */}
      <Navigation config={config} onClose={handleOnClose} />

      <SiteWrapper>
        <MainHeader className="page-head" cover={config.siteCover}>
          <MainNav>
            <BlogLogo logo={config.siteLogo} title={config.siteTitle} />
            <MenuButton
              navigation={config.siteNavigation}
              onClick={handleOnClick}
            />
          </MainNav>
        </MainHeader>
        <MainContent>
          <Layout location={props.location}>
            <div className="page-container">
              <Helmet title={`Privacy Policy | ${config.siteTitle}`} />
              <PrivacyPolicy />
            </div>
          </Layout>
        </MainContent>

        {/* The tiny footer at the very bottom */}
        <Footer
          copyright={config.copyright}
          promoteGatsby={config.promoteGatsby}
        />
      </SiteWrapper>
    </Drawer>
  );
}

export default PrivacyPolicyPage;
