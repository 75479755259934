import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => (
  <section className="static-contents">
    <h1>Privacy Policy</h1>
    <p>2021.03.10 更新</p>
    <p>ryonkmr.com(以下「本サイト」)における個人情報の取扱いについて、以下のとおりにプライバシーポリシーを定めます。</p>
    <section>
      <h2>本サイトが使用しているアクセス解析ツールについて</h2>
      <p>本サイトには以下の情報収集モジュールが組み込まれています。これに伴い、以下のとおり情報収集モジュール提供者への利用者情報の提供を行います。</p>
      <dl>
        <dt>情報収集モジュールの名称</dt>
        <dd>Google Analytics</dd>
        <dt>情報収集モジュールの提供者</dt>
        <dd>Google</dd>
        <dt>Google Analyticsのサービス利用規約及びGoogle社プライバシーポリシー</dt>
        <dd>
          <ul>
            <li><a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank">Google Analytics サービス利用規約</a></li>
            <li><a href="https://policies.google.com/privacy?gl=jp" target="_blank">Google社プライバシーポリシー</a></li>
          </ul>
        </dd>
        <dt>利用目的</dt>
        <dd>本サイトで提供するサービスの向上のため、本サイトの利用者数やトラフィックを調査するため、新規コンテンツ等を展開するにあたっての参考資料とするため。</dd>
      </dl>
      <p>利用者情報収集については Cookie を使用しています。ユーザはこれを無効化することで情報収集を拒否することが出来ます。</p>
    </section>
  </section>
);

export default PrivacyPolicy;
